
























import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import coreApiWorkerClient from "@/services/apis/coreApiWorkerClient";
import DataContainer from "@vuetifyx/common/DataContainer";
import userManager from "@/services/userManager";
import makeSelectOptions from "@common/util/makeSelectOptions";
import orderStatuses from "@/constants/orderStatuses";
import orderShippingStatuses from "@/constants/orderShippingStatuses";

export default Vue.extend({
  data() {
    return {
      self: this,
      market: null,
      tableOptions: null,
    };
  },
  methods: {
    makeTableOptions(market) {
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "dxkTime",
          "sort-desc": true,
          "show-select": true,
        },
        content: {
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            colLength: 2,
            filters: {
              orderId: {
                attrs: {
                  label: "Mã đơn hàng",
                },
                rules: {
                  equal_to: {},
                },
              },
              "productItems.product._id": {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              "productCombos._id": {
                type: "XAutocomplete",
                attrs: {
                  label: "Combo",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productCombos", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "marketId",
                                operator: "equal_to",
                                value: market._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              "productItems.product.productGroupId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Nhóm sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("productGroups", "findAll", {
                          payload: JSON.stringify({
                            limit,
                            search,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: market.countryId,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              shippingCompanyId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Đơn vị vận chuyển",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("shippingCompanies", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              transportTrackingNumber: {
                attrs: {
                  label: "Tracking Number",
                },
                rules: {
                  equal_to: {},
                },
              },
              shippingStatus: {
                type: "selectMultiple",
                attrs: {
                  label: "Trạng thái giao hàng",
                  items: [
                    {
                      text: "Trống",
                      value: "",
                    },
                  ].concat(makeSelectOptions(orderShippingStatuses)),
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      let hasEmptyFilter = false;
                      const arr: any = [];
                      for (const item of value) {
                        if (!item) {
                          hasEmptyFilter = true;
                        } else {
                          arr.push(item);
                        }
                      }
                      const subFilters = [];
                      if (hasEmptyFilter) {
                        subFilters.push({
                          key: "shippingStatus",
                          operator: "not_has_property",
                        });
                      }
                      if (arr.length) {
                        subFilters.push({
                          key: "shippingStatus",
                          operator: "in",
                          value: arr,
                        });
                      }
                      return [
                        {
                          operator: "or",
                          subFilters,
                        },
                      ];
                    },
                  },
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày tạo",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              customerMessageExist: {
                type: "select",
                attrs: {
                  label: "Có lời nhắn",
                  items: [
                    { value: "", text: "Trống" },
                    { value: "yes", text: "Có" },
                    { value: "no", text: "Không" },
                  ],
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value === "yes") {
                        return [
                          {
                            key: "customerMessage",
                            operator: "not_equal_to",
                            value: "",
                          },
                          {
                            key: "customerMessage",
                            operator: "has_property",
                          },
                        ];
                      } else if (value === "no") {
                        return {
                          operator: "or",
                          subFilters: [
                            {
                              key: "customerMessage",
                              operator: "equal_to",
                              value: "",
                            },
                            {
                              key: "customerMessage",
                              operator: "not_has_property",
                            },
                          ],
                        };
                      }
                      return [];
                    },
                  },
                },
              },
              dxkTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày xuất kho",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày xuất kho (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày xuất kho (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              totalPriceAndCodEncodeDelta: {
                type: "boolean",
                attrs: {
                  label: "Có chênh lệch tổng giá và COD Encode",
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value) {
                        return [
                          {
                            key: "cod",
                            operator: "greater_than",
                            value: 0,
                          },
                          {
                            key: "totalPriceAndCodEncodeDelta",
                            operator: "not_equal_to",
                            value: 0,
                          },
                          {
                            key: "totalPriceAndCodEncodeDelta",
                            operator: "has_property",
                          },
                        ];
                      }
                      return [];
                    },
                  },
                },
              },
              warehouseId: userManager.checkRole(["kt", "qlk", "vd", "vdl"]) && {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho",
                  "item-value": "_id",
                  "item-text": "name",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          limit: -1,
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              remmitanceDataNotExist: {
                type: "boolean",
                attrs: {
                  label: "Không có dữ liệu Remittance",
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value) {
                        return [
                          {
                            key: "realCod",
                            operator: "equal_to",
                            value: 0,
                          },
                        ];
                      }
                      return [];
                    },
                  },
                },
              },
              realShippingCostDataNotExist: {
                type: "boolean",
                attrs: {
                  label: "Chưa được trừ phí ship",
                },
                rules: {
                  custom: {
                    filterMaker(value) {
                      if (value) {
                        return [
                          {
                            operator: "or",
                            subFilters: [
                              {
                                key: "realShippingCost",
                                operator: "not_has_property",
                              },
                              {
                                key: "realShippingCost",
                                operator: "equal_to",
                                value: 0,
                              },
                            ],
                          },
                        ];
                      }
                      return [];
                    },
                  },
                },
              },
              deliveredOrReturnedTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày giao hàng / return",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày giao hàng / return (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày giao hàng / return (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              returnTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày return thực tế",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày return thực tế (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày return thực tế (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          displayFields: {
            orderId: {
              text: "ID",
              sortable: true,
              width: "100px",
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            updatedTime: {
              text: "Ngày cập nhật",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            dxkTime: {
              text: "Ngày xuất kho",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            productOrCombo: {
              text: "Sản phẩm",
            },
            giftedProduct: {
              text: "Quà tặng",
              options: {
                subProp: "giftedProduct.sku",
                label: true,
                sortBy: "giftedProduct.name",
              },
            },
            shippingStatus: {
              text: "TT Giao hàng",
              sortable: true,
              options: {
                label(value) {
                  return orderShippingStatuses[value || ""];
                },
              },
            },
            singlePrice: {
              text: "Đơn giá",
              options: {
                preTransform(_, item) {
                  let value =
                    (item.product && item.product.localPrice) ||
                    (item.productCombo && item.productCombo.overriddenLocalPrice) ||
                    item.productCombo?.localPrice;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value;
                },
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            count: {
              text: "Số lượng",
              sortable: true,
            },
            totalPrice: {
              text: "Tổng giá",
              options: {
                preTransform(_, item) {
                  let value =
                    (item.product && item.product.localPrice) ||
                    (item.productCombo && item.productCombo.overriddenLocalPrice) ||
                    item.productCombo?.localPrice;
                  if (isNaN(value)) {
                    value = 0;
                  }
                  return value * item.count;
                },
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            exchangeRate: {
              text: "Tỷ giá",
              sortable: true,
              options: {
                subProp: "market.exchangeRate",
                filter: "currency",
              },
            },
            shippingCompany: {
              text: "ĐVVC",
              options: {
                subProp: "shippingCompany.name",
                sortBy: "shippingCompanyId",
              },
            },
            transportTrackingNumber: {
              text: "Tracking Number",
              sortable: true,
            },
            deliveredOrReturnedTime: {
              text: "Ngày giao hàng / return",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            returnTime: {
              text: "Ngày return thực tế",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            weight: {
              text: "Cân nặng",
              sortable: true,
            },
            receivableFreight: {
              text: "Receivable Freight",
              sortable: true,
            },
            shippingCost: {
              text: "Phí ship",
              sortable: true,
              options: {
                filter: "number",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            insuranceCost: {
              text: "Phí bảo hiểm",
              sortable: true,
              options: {
                filter: "number",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            codCost: {
              text: "Phí THCOD",
              sortable: true,
              options: {
                filter: "number",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            cod: {
              text: "COD",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            area: {
              text: "Area",
            },
            totalPriceAndCodEncodeDelta: {
              text: "Chênh lệnh giữa Tổng giá và COD encode",
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} ${market.currency}`;
                },
              },
            },
            warehouse: {
              text: "Kho",
              options: {
                subProp: "warehouse.name",
                sortBy: "warehouseId",
              },
            },
            baseCost: userManager.checkRole(["kt"]) && {
              text: "Base Cost",
            },
            baseCostOk: market.tmdt && {
              text: "Base Cost OK",
              options: {
                boolean: true,
              },
            },
            action: {},
          },
          topActionButtons: {
            export: userManager.checkRole(["kt", "vdl", "vd"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiWorkerClient.download("orders", `@/export`, findAllOptions);
                },
              },
            },
            importTransportStatus: userManager.checkRole(["kt", "vd", "vdl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                icon: "mdi-upload",
                text: "Cập nhật trạng thái vận chuyển",
              },
              target: {
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Cập nhật trạng thái vận chuyển",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                  importType: {
                                    type: "select",
                                    attrs: {
                                      items: [
                                        {
                                          value: "trackingNumberAndPhoneNumber",
                                          text: "Map Tracking Number + SĐT",
                                        },
                                        {
                                          value: "trackingNumber",
                                          text: "Map Tracking Number",
                                        },
                                      ],
                                    },
                                    ext: {
                                      defaultValue: "trackingNumberAndPhoneNumber",
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiWorkerClient.call(
                                  "orders",
                                  "importTransportStatus",
                                  {
                                    importType: data.importType,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              const lines = [
                                `Import thành công ${
                                  result.affectedOrderIds.length
                                } đơn: ${result.affectedOrderIds.join(", ")}`,
                                `Tương ứng với các hàng ${result.affectedIndexes.join(", ")}`,
                                "",
                                `${
                                  result.notAffectedIndexes.length
                                } hàng không tìm thấy ${result.notAffectedIndexes.join(",")}`,
                              ];
                              form.success.value = lines.join("<br/>");
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            importRemittanceReport: userManager.checkRole(["kt", "vd", "vdl"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                icon: "mdi-upload",
                text: "Upload Remittance Report",
              },
              target: {
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Upload Remittance Report",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call("orders", "importRemittanceReport", {}, data);
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              const realReceivedAmount =
                                (result.realCodTotal || 0) -
                                (result.realShippingCostTotal || 0) -
                                (result.realCodCostTotal || 0);
                              const lines = [`Import thành công`];
                              lines.push("");
                              lines.push(`COD nhận được: ${Vue.filter("currency")(result.realCodTotal)}`);
                              if (
                                result.realCodNotFoundTransportTrackingNumbers &&
                                result.realCodNotFoundTransportTrackingNumbers.length
                              ) {
                                lines.push(
                                  `COD nhận được (không tìm thấy tracking number): ${result.realCodNotFoundTransportTrackingNumbers.join(
                                    ","
                                  )}`
                                );
                              }
                              lines.push("");
                              lines.push(`Phí ship: ${Vue.filter("currency")(result.realShippingCostTotal)}`);
                              if (
                                result.realShippingCostNotFoundTransportTrackingNumbers &&
                                result.realShippingCostNotFoundTransportTrackingNumbers.length
                              ) {
                                lines.push(
                                  `Phí ship (không tìm thấy tracking number): ${result.realShippingCostNotFoundTransportTrackingNumbers.join(
                                    ","
                                  )}`
                                );
                              }
                              lines.push("");
                              lines.push(`Phí COD thu hộ: ${Vue.filter("currency")(result.realCodCostTotal)}`);
                              if (
                                result.realCodCostNotFoundTransportTrackingNumbers &&
                                result.realCodCostNotFoundTransportTrackingNumbers.length
                              ) {
                                lines.push(
                                  `Phí COD thu hộ (không tìm thấy tracking number): ${result.realCodCostNotFoundTransportTrackingNumbers.join(
                                    ","
                                  )}`
                                );
                              }
                              lines.push("");
                              lines.push(`Số tiền nhận được: ${Vue.filter("currency")(realReceivedAmount)}`);
                              form.success.value = lines.join("<br/>");
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: {},
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "marketId",
              operator: "equal_to",
              value: this.context().$route.params.marketId,
            });
            options.filters.push({
              key: "status",
              operator: "equal_to",
              value: "dxk",
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("orders", "findAll", options);
              return [items, count];
            },
            async update(item) {
              return await coreApiClient.call(
                "orders",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
          },
          default: {
            defaultInputs: {
              status: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: makeSelectOptions(orderStatuses),
                },
                content: {
                  rules: {
                    custom: [async (value) => ["dxk", "ht", "h", "return"].includes(value)],
                  },
                },
              },
              shippingStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái giao hàng",
                  items: makeSelectOptions(orderShippingStatuses),
                },
              },
              deliveredOrReturnedTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày giao hàng / return",
                },
              },
              weight: {
                type: "number",
                attrs: {
                  label: "Cân nặng",
                },
              },
              receivableFreight: {
                type: "number",
                attrs: {
                  label: "Receivable Freight",
                },
              },
              shippingCost: {
                type: "number",
                attrs: {
                  label: "Phí ship",
                },
              },
              insuranceCost: {
                type: "number",
                attrs: {
                  label: "Phí bảo hiểm",
                },
              },
              codCost: {
                type: "number",
                attrs: {
                  label: "Phí THCOD",
                },
              },
              cod: {
                type: "number",
                attrs: {
                  label: "COD",
                },
              },
              area: {
                attrs: {
                  label: "Area",
                },
              },
            },
            editForm: userManager.checkRole(["kt", "vd", "vdl"]) && {
              content: {
                fieldNames: [
                  "status",
                  "shippingStatus",
                  "deliveredOrReturnedTime",
                  "weight",
                  "receivableFreight",
                  "shippingCost",
                  "insuranceCost",
                  "codCost",
                  "cod",
                  "area",
                ],
              },
            },
          },
        },
      };
    },
  },
  async created() {
    this.market = await coreApiClient.call("markets", "findById", {
      id: this.$route.params.marketId,
    });
    this.tableOptions = this.makeTableOptions(this.market);
  },
});
